<template>
    <div class="print">
        <div class="title" v-html="currMatch.matchName"></div>
        <div class="title">选手成绩表</div>
        <div class="card-title">参赛单位：<span v-html="currTeam.teamName"></span></div>
        <div class="table">
            <div class="head">
                <div class="th">
                    <div class="tr">背号</div>
                    <div class="tr">选手1</div>
                    <div class="tr">选手2</div>
                    <div class="tr">参赛组别</div>
                    <div class="tr">舞种</div>
                    <div class="tr">分组</div>
                    <div class="tr">成绩</div>
                </div>
            </div>
            <div class="tbody" v-for="item in teamAllScores" :key="item.id">
                <div class="td" v-html="item.playerNumber"></div>
                <div class="td" v-html="item.malePlayerName"></div>
                <div class="td" v-html="item.femalePlayerName"></div>
                <div class="td" v-html="item.name"></div>
                <div class="td" v-html="item.dance"></div>
                <div class="td" v-html="item.grouping"></div>
                <div class="td" v-html="item.cnAchievement"></div>
            </div>
        </div>
        <div class="table table2">
            <div class="head">
                <div class="th">
                    <div class="tr">奖品名称</div>
                    <div class="tr">数量</div>
                    <div class="tr">计量单位</div>
                </div>
            </div>
            <div class="tbody" v-for="item in prizesTableData" :key="item.id">
                <div class="td" v-html="item.prizeName"></div>
                <div class="td" v-html="item.prizeCount"></div>
                <div class="td" v-html="item.prizeUnit"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "print",
    data() {
        return {
            matchId: sessionStorage.getItem("currMatchId"),
            teamAllScores: [],
            currMatch: {},
            currTeam:{},
            prizesTableData:[]
        }
    },
    created() {
        this.init();
    },
    mounted() {
        document.title = "我是记分长-网上报名系统";
        window.print()
    },
    beforeDestroy() {

    },
    methods: {
        init() {
            const teamAllScores = localStorage.getItem("teamAllScores");
            if (teamAllScores) {
                this.teamAllScores = JSON.parse(teamAllScores)
            }
            const currMatch = localStorage.getItem("currMatch");
            if (currMatch) {
                this.currMatch = JSON.parse(currMatch)
            }
            const currTeam = localStorage.getItem("currTeam");
            if (currTeam) {
                this.currTeam = JSON.parse(currTeam)
            }
            const prizesTableData = localStorage.getItem("prizesTableData");
            if (prizesTableData) {
                this.prizesTableData = JSON.parse(prizesTableData)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.print {
    // display: none;
    width: calc(100% - 40px);
    padding: 20px;
    height: 99.95%;
    background-color: #fff;

    .title {
        font-size: 24px;
        color: #333333;
        min-height: 30px;
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
    }

    .card-title {
        font-size: 14px;
        color: #333333;
        margin-bottom: 5px;
    }

    .table {
        margin: 0px auto 20px;
        width: 100%;

        .head {
            .th {
                display: grid;
                grid-template-columns: 8% 12% 12% 1fr 10% 10% 10%;
                grid-template-rows: minmax(44px,auto);
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                overflow: hidden;

                .tr {
                    border-top: 1px solid #eee;
                    border-right: 1px solid #eee;
                    border-bottom: 1px solid #eee;

                    &:nth-child(1) {
                        border-left: 1px solid #eee;
                    }

                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 0px 6px;
                    font-size: 14px;
                    background-color: #D7D7D7 !important;
                }
            }
        }

        .tbody {
            display: grid;
            grid-template-columns: 8% 12% 12% 1fr 10% 10% 10%;
            grid-template-rows: minmax(44px,auto);

            .td {
                border: 1px solid #eee;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 0px 6px;
                font-size: 14px;
            }
        }
    }

    .table2 {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin: 0px auto 20px;
        width: 600px;

        .head {
            width: 100%;

            .th {
                grid-template-columns: repeat(3, 1fr);
            }
        }

        .tbody {
            grid-template-columns: repeat(3, 1fr);
            width: 100%;

        }
    }
}
</style>